import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_json_pretty = _resolveComponent("vue-json-pretty")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass({ 'has-background-black': _ctx.isDark })
    }, [
      _createElementVNode("p", {
        class: _normalizeClass({ 'has-text-white': _ctx.isDark })
      }, "About", 2)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass({ 'has-background-black': _ctx.isDark })
    }, [
      (_ctx.info)
        ? (_openBlock(), _createBlock(_component_vue_json_pretty, {
            key: 0,
            data: _ctx.info,
            deep: _ctx.expandDepth,
            deepCollapseChildren: _ctx.deepCollapse,
            showLength: true
          }, null, 8, ["data", "deep", "deepCollapseChildren"]))
        : _createCommentVNode("", true)
    ], 2)
  ], 64))
}